import React from 'react';
import {Container, Grid, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';

import {AllClearIDLogo} from '../assets/icons/allClearIDIcon';
import {LockIcon as LockIconShared} from '../assets/icons/lockIcon';
import {InfoBlock, MedicalRecordsRequestForm} from '../components';
import {Description} from '../components/shared';

const useStyles = makeStyles((theme: Theme) => ({
  gridWrapper: {
    paddingTop: theme.spacing(10),
  },
  containerWrapper: {
    paddingBottom: theme.spacing(10),
  },
  infoBlockWrapper: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  descriptionWrapper: {
    marginBottom: 40,
  },
  securityBlock: {
    fontSize: 15,
    fontWeight: 400,
    display: 'flex',
  },
  lockIcon: {
    color: theme.palette.common.black,
    width: theme.spacing(1.5),
    marginRight: theme.spacing(0.5),
  },
  svgIcon: {
    '&.MuiSvgIcon-root': {
      width: theme.spacing(10),
      height: theme.spacing(2.75),
      marginLeft: theme.spacing(0.5),
      color: theme.palette.primary.main,
    },
  },
}));

function RequestMedicalRecordsPage(): JSX.Element {
  const classes = useStyles();

  return (
    <Container disableGutters className={classes.containerWrapper}>
      <Grid container spacing={6}>
        <Grid item xs={9}>
          <div
            className={clsx(classes.descriptionWrapper, classes.gridWrapper)}
          >
            <Description title="Patient Medical Records Request" />
          </div>
          <MedicalRecordsRequestForm />
          <div className={classes.securityBlock}>
            <LockIconShared className={classes.lockIcon} />
            <span>Security Guaranteed by </span>
            <AllClearIDLogo className={classes.svgIcon} />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className={clsx(classes.gridWrapper, classes.infoBlockWrapper)}>
            <InfoBlock />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
export default RequestMedicalRecordsPage;
