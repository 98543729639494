import React from 'react';
import {SvgIcon, SvgIconProps} from '@mui/material';

export function AllClearIDLogo(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} width="200" height="33" viewBox="0 0 200 33">
      <path d="M164.101 0.133972V32.5981H153.755V0.133972H164.101Z" />
      <path d="M200.011 16.2767C200.011 25.0626 195.613 32.5535 186.179 32.5535H168.994V0H182.188C193.601 0 200.011 3.80683 200.011 16.2767ZM191.765 13.5751H186.091V7.8481H180.814V13.5751H175.162V18.9783H180.814V24.7053H186.091V18.9783H191.732L191.765 13.5751Z" />
      <path d="M35.734 32.5646H30.6543V0.122803H35.734V32.5646Z" />
      <path d="M45.7833 32.5646H40.7036V0.122803H45.7833V32.5646Z" />
      <path d="M64.2883 4.499C62.9555 4.4399 61.6275 4.69897 60.4112 5.25541C59.1948 5.81185 58.1239 6.65015 57.2845 7.70299C55.5839 9.84643 54.7336 12.7974 54.7336 16.5558C54.7336 20.4966 55.5582 23.4662 57.1855 25.4868C58.0595 26.5138 59.1551 27.322 60.3871 27.8487C61.619 28.3754 62.9541 28.6063 64.2883 28.5234C65.5966 28.5287 66.9016 28.3903 68.1806 28.1103C69.434 27.8424 70.7424 27.4963 72.0948 27.0721V31.5376C69.392 32.528 66.5346 33.0121 63.6616 32.9665C59.0437 32.9665 55.4813 31.5376 53.0074 28.6908C50.5335 25.8441 49.2911 21.7805 49.2911 16.4777C49.2207 13.4546 49.8377 10.4558 51.0942 7.71416C52.2295 5.30824 54.0638 3.31339 56.3499 1.99833C58.8003 0.623432 61.5663 -0.0661685 64.3653 1.97738e-05C67.4696 -0.0270311 70.5376 0.680058 73.3262 2.06531L71.4241 6.39684C70.3289 5.87293 69.2014 5.42171 68.0486 5.04603C66.8301 4.66958 65.5619 4.48509 64.2883 4.499Z" />
      <path d="M81.3302 32.5646H76.2505V0.122803H81.3302V32.5646Z" />
      <path d="M96.1078 33C94.5052 33.0858 92.9026 32.8323 91.4016 32.2558C89.9006 31.6793 88.5341 30.7922 87.3887 29.6509C85.2923 27.4181 84.2441 24.3853 84.2441 20.5524C84.2441 16.7195 85.208 13.616 87.1358 11.2419C88.1395 10.1032 89.381 9.20663 90.7697 8.61754C92.1584 8.02844 93.6591 7.76175 95.1622 7.83693C96.541 7.76334 97.9195 7.9858 99.2078 8.48979C100.496 8.99378 101.665 9.76797 102.639 10.7618C104.561 12.9648 105.549 15.8497 105.388 18.7885V21.5795H89.4778C89.4095 23.5672 90.0807 25.5082 91.3579 27.0162C92.0142 27.6677 92.7971 28.173 93.6566 28.4998C94.5162 28.8266 95.4334 28.9677 96.3497 28.9141C97.6758 28.9232 98.999 28.7885 100.297 28.5122C101.645 28.1913 102.96 27.7389 104.222 27.1613V31.3477C103.028 31.9347 101.764 32.3625 100.462 32.6204C99.0262 32.8907 97.5679 33.0179 96.1078 33ZM95.1842 11.7889C94.4654 11.7574 93.7481 11.8804 93.0793 12.1497C92.4105 12.4191 91.8052 12.8287 91.303 13.3518C90.239 14.626 89.6273 16.2261 89.5658 17.8955H100.418C100.509 16.2504 100.001 14.6287 98.9885 13.3407C98.5081 12.8081 97.9184 12.3891 97.2611 12.1133C96.6037 11.8374 95.8948 11.7115 95.1842 11.7442V11.7889Z" />
      <path d="M124.156 32.5646L123.134 29.2155H122.969C122.055 30.4831 120.861 31.5154 119.483 32.2297C118.056 32.8142 116.524 33.088 114.986 33.0335C112.974 33.1545 110.997 32.4599 109.489 31.1022C108.804 30.3792 108.274 29.5196 107.934 28.578C107.593 27.6364 107.449 26.6332 107.51 25.6319C107.44 24.5256 107.653 23.4197 108.129 22.4219C108.605 21.4242 109.327 20.5685 110.226 19.9384C112.062 18.6658 114.854 17.9624 118.593 17.8508L122.727 17.7168V16.4442C122.816 15.1827 122.422 13.9351 121.627 12.9611C121.181 12.5572 120.659 12.2498 120.091 12.0579C119.524 11.866 118.925 11.7934 118.329 11.8447C117.132 11.8439 115.942 12.0283 114.799 12.3917C113.694 12.7565 112.61 13.189 111.556 13.6867L109.918 10.0027C111.265 9.29766 112.695 8.76875 114.173 8.42862C115.614 8.08262 117.09 7.90287 118.571 7.89276C121.605 7.89276 123.9 8.56631 125.454 9.9134C127.008 11.2605 127.796 13.3555 127.818 16.1986V32.5646H124.156ZM116.592 29.048C118.202 29.1138 119.776 28.5505 120.99 27.474C121.561 26.9045 122.006 26.2173 122.295 25.4586C122.583 24.6999 122.708 23.8873 122.661 23.0754V20.9655L119.593 21.0995C117.778 21.0396 115.979 21.4589 114.371 22.3163C113.831 22.6775 113.396 23.1782 113.11 23.7668C112.824 24.3554 112.697 25.0107 112.743 25.6654C112.714 26.1285 112.783 26.5927 112.946 27.0261C113.109 27.4596 113.363 27.8523 113.689 28.1773C114.512 28.8327 115.55 29.1442 116.592 29.048Z" />
      <path d="M144.134 7.83694C144.983 7.82231 145.83 7.89714 146.663 8.06021L146.168 12.8606C145.429 12.6895 144.673 12.6034 143.914 12.6039C142.998 12.567 142.084 12.7281 141.233 13.0764C140.382 13.4247 139.613 13.9523 138.978 14.6245C138.337 15.3291 137.84 16.1555 137.516 17.0561C137.191 17.9566 137.045 18.9134 137.087 19.8714V32.5646H132.007V8.27232H135.976L136.647 12.5592H136.911C137.648 11.1675 138.714 9.98247 140.011 9.1096C141.23 8.27819 142.666 7.83506 144.134 7.83694Z" />
      <path d="M17.4601 0.133972H11.8307L0 32.5646H5.49753L7.87246 25.7213L10.0715 19.1235L12.9302 10.7618C13.6193 8.83141 14.1885 6.859 14.6344 4.85623C14.7773 5.43675 15.0412 6.36334 15.4261 7.61368C15.8109 8.86401 16.1077 9.84642 16.3167 10.3823L19.2853 19.1235H13.304L11.7537 23.6448H20.6377L23.7933 32.5758H29.2908L17.4601 0.133972Z" />
    </SvgIcon>
  );
}
