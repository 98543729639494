import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import {useRecoilState, useSetRecoilState} from 'recoil';

import {contactSupportModalState} from '../../../atoms/contactSupportModalStatr';
import {pageTypeState} from '../../../atoms/pageTypeState';
import {PAGE_TYPE} from '../../../constants/types';

import BaseModalWindow from './baseModalWindow';

const TITLE = 'Attempts limit reached';
const DESCRIPTION =
  'You have reached your limit of attempts to enter the patient information. Please contact AllClear ID support.';

function ContactSupportModalWindow(): JSX.Element {
  const [isOpenContactSupportModalWindow, setIsOpenContactSupportModalWindow] =
    useRecoilState(contactSupportModalState);
  const setLoginPage = useSetRecoilState(pageTypeState);

  const onClickContactSupportBtn = () => {
    window.open(
      'https://allclearid.atlassian.net/servicedesk/customer/portals',
      '_blank',
      'noopener'
    );
    setLoginPage(PAGE_TYPE.LOGIN);
    setIsOpenContactSupportModalWindow(false);
  };

  return (
    <BaseModalWindow open={isOpenContactSupportModalWindow}>
      <>
        <Typography component="h6" variant="h6">
          {TITLE}
        </Typography>
        <Typography marginTop={2}>{DESCRIPTION}</Typography>
        <Box display="flex" marginTop={5} justifyContent="flex-end">
          <Button
            variant="contained"
            data-testid="modal-contact-support-btn"
            size="presized"
            onClick={onClickContactSupportBtn}
          >
            Contact Support
          </Button>
        </Box>
      </>
    </BaseModalWindow>
  );
}

export default ContactSupportModalWindow;
