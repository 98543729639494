import React from 'react';
import {Box, Button, CircularProgress, Typography} from '@mui/material';
import {Form, Formik} from 'formik';
import {useSetRecoilState} from 'recoil';
import * as yup from 'yup';

import {confirmLeavePageModalState} from '../atoms/confirmLeavePageModalState';
import {DATE_OF_BIRTH_START_YEAR} from '../constants/date';
import {onlyTenDigitsPattern} from '../constants/patterns';
import {requestMedicalRecordsInitialValues} from '../constants/requestMedicalRecordsInitialValues';
import {FormValues} from '../constants/types';
import usePatientMedicalRecords from '../hooks/usePatientMedicalRecords';
import {dateIsBetween, dayjs} from '../utils/formatDate';

import PatientInformationSubForm from './patientInformationSubForm';
import RequestRecordsRadioBtnBlock from './requestRecordsRadioBtnBlock';
import UserCommentSubForm from './userCommentSubForm';
import UserInformationSubForm from './userInformationSubForm';

const EMPTY_DAY_OF_BIRTH_VALUE = '__/__/____';

const formValidationSchema = yup.object({
  patientFirstName: yup.string().trim().required('First Name is required'),
  patientLastName: yup.string().trim().required('Last Name is required'),
  patientDateOfBirth: yup
    .string()
    .trim()
    .nullable()
    .test(
      'patientDateOfBirthIsRequiredCheck',
      'Date of Birth is required',
      value => {
        if (!value) {
          return false;
        }

        return !value.includes(EMPTY_DAY_OF_BIRTH_VALUE);
      }
    )
    .test('patientDateOfBirthRangeCheck', 'Date is not valid', value => {
      const isDataValid = dayjs(value, 'MM/DD/YYYY', true).isValid();

      if (isDataValid && value) {
        return dateIsBetween(
          String(DATE_OF_BIRTH_START_YEAR),
          value,
          new Date().toString()
        );
      }

      return false;
    }),
  userFirstName: yup.string().trim().required('First Name is required'),
  userLastName: yup.string().trim().required('Last Name is required'),
  userEmail: yup
    .string()
    .trim()
    .email('Invalid email format')
    .required('Email is required'),
  userPhoneNumber: yup
    .string()
    .trim()
    .when(phone => {
      if (phone[0]) {
        return yup
          .string()
          .test(
            'firstCharacter',
            'Phone number cannot start with 0',
            value => !!(value && Number(value[0]) !== 0)
          )
          .matches(onlyTenDigitsPattern, {
            message: 'Phone number must be 10 digits',
          })
          .required();
      }
      return yup.string().notRequired();
    }),
});

function MedicalRecordsRequestForm(): JSX.Element {
  const setConfirmLeavePageModalState = useSetRecoilState(
    confirmLeavePageModalState
  );

  const onCancel = () => {
    setConfirmLeavePageModalState(true);
  };

  const {
    requestPatientMedicalRecords,
    isPatientMedicalRecordsLoading,
    isMatchedPatientInfoError,
    attempts,
    setIsMatchedPatientInfoError,
  } = usePatientMedicalRecords();

  const getLeftAttemptsMessage = (attemptsCount: number): string =>
    `You have ${
      attemptsCount === 1 ? '1 attempt' : `${attemptsCount} attempts`
    } left to provide correct data.`;

  const handleSubmit = (values: FormValues) => {
    requestPatientMedicalRecords(values);
  };

  return (
    <Formik
      validateOnMount
      validationSchema={formValidationSchema}
      initialValues={requestMedicalRecordsInitialValues}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Form noValidate>
          <PatientInformationSubForm
            isMatchedPatientInfoError={isMatchedPatientInfoError}
            setIsMatchedPatientInfoError={setIsMatchedPatientInfoError}
          />
          <RequestRecordsRadioBtnBlock />
          <UserInformationSubForm />
          <UserCommentSubForm />
          <Box mb={5}>
            <Button
              size="presized"
              variant="outlined"
              data-testid="cancel-btn"
              onClick={onCancel}
              sx={{mr: 1.5}}
            >
              Cancel
            </Button>
            <Button
              size="presized"
              variant="contained"
              type="submit"
              disabled={
                !formik.isValid ||
                isPatientMedicalRecordsLoading ||
                isMatchedPatientInfoError
              }
              data-testid="next-btn"
            >
              {isPatientMedicalRecordsLoading ? (
                <CircularProgress size={22} />
              ) : (
                'Next'
              )}
            </Button>
            <Box marginTop={2}>
              <Typography>{getLeftAttemptsMessage(attempts)}</Typography>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

export default MedicalRecordsRequestForm;
