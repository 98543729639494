import React from 'react';
import {DropzoneRef, useDropzone} from 'react-dropzone';
import {Box, Button, Divider, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';

import {wrapAsyncFunction} from '../../utils/wrapAsyncFunction';

const useStyles = makeStyles((theme: Theme) => ({
  dropzoneIsDragAccepted: {
    borderWidth: '4px',
    borderStyle: 'dashed',
    backgroundColor: `${theme.palette.secondary.main}10`,
    borderColor: theme.palette.secondary.main,
  },
  dropzoneIsWaiting: {
    borderWidth: '4px',
    borderStyle: 'dashed',
    borderColor: theme.palette.divider,
  },
  dropzone: {
    borderRadius: '8px',
    padding: '30px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropzoneHasNoFiles: {
    minHeight: '300px',
  },
  dropzoneHasFiles: {
    minHeight: '160px',
  },
}));

function FixedDivider() {
  return (
    <Box
      marginTop="16px"
      marginBottom="16px"
      marginLeft="auto"
      marginRight="auto"
      width="128px"
    >
      <Divider />
    </Box>
  );
}

function DropZoneScreen({
  open,
  isDragAccept,
  hasFiles,
}: DropzoneRef & {isDragAccept: boolean; hasFiles: boolean}): JSX.Element {
  return (
    <Box color="textSecondary" textAlign="center">
      <Typography
        variant="subtitle1"
        component="p"
        marginBottom={1}
        color={isDragAccept ? 'secondary.main' : 'text.secondary'}
      >
        Drag and drop your files here. Supported file extensions: <br />
        <b>ccd, сcda, dcm, jpeg, jpg, pdf, png, xml, zip</b>
      </Typography>
      {!isDragAccept ? (
        <>
          <FixedDivider />
          <Typography
            variant="subtitle1"
            marginBottom={1}
            component="p"
            color="textSecondary"
          >
            OR
          </Typography>
        </>
      ) : null}
      {!isDragAccept ? (
        <Button
          size="presizedSmall"
          variant="contained"
          onClick={open}
          data-testid="click-to-browse-btn"
        >
          {hasFiles ? 'Click to Browse' : 'Browse on Device'}
        </Button>
      ) : null}
    </Box>
  );
}

type Props = {
  hasFiles: boolean;
  onDrop: (files: File[]) => Promise<void>;
};
export function DropZone({hasFiles, onDrop}: Readonly<Props>): JSX.Element {
  const {getRootProps, getInputProps, open, isDragAccept} = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: wrapAsyncFunction(onDrop),
  });
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.dropzone, {
        [classes.dropzoneIsWaiting]: !isDragAccept,
        [classes.dropzoneIsDragAccepted]: isDragAccept,
        [classes.dropzoneHasNoFiles]: !hasFiles,
        [classes.dropzoneHasFiles]: hasFiles,
      })}
      {...getRootProps()}
    >
      <input data-testid="dropzone" {...getInputProps()} />
      <DropZoneScreen
        open={open}
        isDragAccept={isDragAccept}
        hasFiles={hasFiles}
      />
    </div>
  );
}
