import React from 'react';
import {SvgIconProps} from '@mui/material';

export function HealthConnectLogoIcon(props: SvgIconProps): JSX.Element {
  return (
    <svg viewBox="0 0 205 56" {...props}>
      <g fill="#fff" clipPath="url(#a)">
        <path
          fillRule="evenodd"
          d="M195.215 15.714c0 8.481-4.335 15.713-13.5 15.713h-16.764V0h12.836c11.182 0 17.439 3.686 17.439 15.714h-.011Zm-8.049-2.609h-5.505V7.577h-5.184v5.54h-5.516v5.205h5.516v5.529h5.184v-5.53h5.505v-5.216Z"
          clipRule="evenodd"
        />
        <path d="M160.122.129v31.34h-10.11V.13h10.11ZM29.92 31.439h4.959V.119H29.92v31.32Zm14.767 0H39.73V.119h4.958v31.32ZM55.913 7.447c1.653-2.058 3.94-3.093 6.837-3.093v-.01c1.277 0 2.5.183 3.66.56 1.158.377 2.264.808 3.305 1.304l1.813-4.214C68.856.668 65.948 0 62.793 0c-2.995 0-5.613.647-7.835 1.93-2.221 1.282-3.928 3.135-5.097 5.55-1.17 2.414-1.76 5.237-1.76 8.46 0 5.109 1.202 9.042 3.627 11.79 2.425 2.76 5.892 4.128 10.41 4.128 3.07 0 5.806-.463 8.232-1.38v-4.343c-1.32.42-2.598.755-3.821 1.014-1.224.258-2.49.388-3.8.388-3.026 0-5.333-.981-6.932-2.921-1.6-1.94-2.393-4.99-2.393-8.622 0-3.632.826-6.488 2.49-8.547Zm23.46 23.992h-4.958V.119h4.958v31.32Z" />
        <path
          fillRule="evenodd"
          d="M85.297 28.669c2.039 2.123 4.883 3.19 8.51 3.19l.011.01c1.588 0 3.005-.118 4.24-.355a15.022 15.022 0 0 0 3.67-1.229v-4.052c-1.363.604-2.64 1.046-3.832 1.304a18.171 18.171 0 0 1-3.853.388c-2.092 0-3.724-.614-4.872-1.832-1.148-1.229-1.77-2.975-1.835-5.249h15.551V18.15c0-3.298-.902-5.885-2.683-7.76-1.782-1.886-4.218-2.824-7.298-2.824-3.327 0-5.935 1.089-7.835 3.288-1.899 2.198-2.843 5.302-2.843 9.031 0 3.73 1.03 6.66 3.069 8.784Zm3.82-15.822c.956-1.002 2.211-1.508 3.789-1.508 1.578 0 2.812.506 3.713 1.498.902.991 1.363 2.457 1.395 4.397H87.422c.182-1.919.751-3.384 1.696-4.387Zm32.057 18.592-.988-3.266h-.172c-1.126 1.423-2.253 2.403-3.402 2.91-1.148.507-2.607.776-4.389.776-2.297 0-4.089-.625-5.366-1.865-1.288-1.239-1.932-3.006-1.932-5.28 0-2.415.891-4.236 2.683-5.465 1.793-1.228 4.508-1.897 8.167-2.015l4.036-.13v-1.25c0-1.498-.344-2.619-1.041-3.362-.698-.744-1.782-1.11-3.241-1.11-1.192 0-2.34.172-3.435.528a27.332 27.332 0 0 0-3.166 1.25l-1.609-3.557c1.266-.668 2.65-1.164 4.153-1.52 1.502-.344 2.93-.517 4.26-.517 2.973 0 5.205.647 6.719 1.951 1.513 1.304 2.264 3.341 2.264 6.122v15.789h-3.541v.01Zm-7.384-3.395c1.803 0 3.252-.507 4.336-1.51 1.094-1.012 1.631-2.424 1.631-4.245V20.25l-2.994.13c-2.34.086-4.036.474-5.098 1.175-1.062.7-1.588 1.767-1.588 3.211 0 1.046.311 1.854.923 2.425.611.572 1.545.863 2.79.863v-.011Z"
          clipRule="evenodd"
        />
        <path d="M143.142 7.782c-.644-.14-1.47-.216-2.468-.216l.021.011c-1.438 0-2.779.41-4.024 1.229-1.245.819-2.254 1.929-3.027 3.33h-.257l-.655-4.138h-3.885v23.44h4.958V19.186c0-2.08.612-3.762 1.846-5.066 1.224-1.304 2.833-1.95 4.819-1.95.751 0 1.492.086 2.189.258l.483-4.645ZM17.032.129h-5.484L0 31.438h5.323l2.318-6.607 2.211-6.37h-.021l2.79-8.072c.73-2.058 1.288-3.966 1.663-5.701.14.56.397 1.455.773 2.673.376 1.217.665 2.101.88 2.672l2.887 8.44h-5.828l-1.513 4.364h8.672l3.08 8.6H28.6L17.03.13Zm181.85 46.861h.623v-3.223h1.095v-.528h-2.812v.528h1.094v3.223Zm3.896 0-.003-.01h.519l1.126-3.126h.022l-.006.168-.008.185-.008.185c-.01.302-.01.55-.01.733v1.854h.59v-3.75h-.859l-1.073 2.985h-.011l-1.04-2.986h-.859v3.751h.558v-1.821c0-.4-.011-.841-.043-1.304h.022l1.08 3.125h-.018l.021.01ZM.752 43.326v12.48h2.125v-5.335h6.192v5.335h2.136v-12.48H9.069v5.238H2.888v-5.238H.752Zm15.84 0v12.49h8.972v-1.82h-6.836v-3.59h4.722v-1.778h-4.722v-3.48h6.761v-1.822h-8.897Z" />
        <path
          fillRule="evenodd"
          d="m34.193 43.326-4.883 12.48h2.221l1.277-3.492h5.098l1.277 3.492h2.286l-4.808-12.48h-2.468Zm3.123 7.307H33.43l1.932-5.281 1.953 5.281Z"
          clipRule="evenodd"
        />
        <path d="M45.58 43.326v12.48h8.757v-1.854h-6.622V43.326H45.58Zm14.455 1.854v10.626h2.136V45.18h4.153v-1.853H55.882v1.853h4.154Zm10.625-1.854v12.48h2.136v-5.335h6.181v5.335h2.136v-12.48h-2.136v5.238h-6.181v-5.238H70.66Zm25.65 12.189c.783.324 1.62.485 2.522.485.633 0 1.255-.086 1.846-.27a6.076 6.076 0 0 0 1.652-.764 5.551 5.551 0 0 0 1.352-1.197l-1.416-1.39c-.44.539-.955.96-1.546 1.26a4.173 4.173 0 0 1-1.878.432 4.21 4.21 0 0 1-1.685-.334 4.103 4.103 0 0 1-1.352-.949 4.525 4.525 0 0 1-.89-1.444 4.804 4.804 0 0 1-.323-1.778c0-.636.108-1.229.323-1.778a4.616 4.616 0 0 1 .89-1.423 4.37 4.37 0 0 1 1.352-.96 4.163 4.163 0 0 1 1.685-.333 4.3 4.3 0 0 1 3.424 1.692l1.416-1.39a5.318 5.318 0 0 0-1.352-1.197 6.59 6.59 0 0 0-1.652-.765 6.178 6.178 0 0 0-1.846-.27c-.902 0-1.739.162-2.522.496a6.058 6.058 0 0 0-2.06 1.37 6.383 6.383 0 0 0-1.364 2.047 6.422 6.422 0 0 0-.483 2.511c0 .905.161 1.746.483 2.533a6.755 6.755 0 0 0 1.363 2.047 6.567 6.567 0 0 0 2.06 1.37Z" />
        <path
          fillRule="evenodd"
          d="M111.313 55.515a6.304 6.304 0 0 0 2.468.485c.87 0 1.685-.161 2.447-.485a6.048 6.048 0 0 0 2.007-1.369 6.404 6.404 0 0 0 1.352-2.047 6.608 6.608 0 0 0 .483-2.533 6.46 6.46 0 0 0-.483-2.511 6.178 6.178 0 0 0-1.352-2.048 5.892 5.892 0 0 0-2.007-1.369c-.751-.334-1.567-.495-2.447-.495-.88 0-1.695.161-2.468.495a5.952 5.952 0 0 0-2.007 1.37 6.298 6.298 0 0 0-1.331 2.047 6.422 6.422 0 0 0-.483 2.511c0 .905.161 1.746.483 2.533a6.66 6.66 0 0 0 1.331 2.047 6.458 6.458 0 0 0 2.007 1.37Zm4.089-1.778a3.9 3.9 0 0 1-1.621.334 3.86 3.86 0 0 1-1.62-.334 4.048 4.048 0 0 1-1.31-.949 4.91 4.91 0 0 1-.869-1.444 4.818 4.818 0 0 1-.322-1.778c0-.636.108-1.229.322-1.778a4.795 4.795 0 0 1 .869-1.423c.376-.41.816-.733 1.31-.96a3.894 3.894 0 0 1 1.62-.333c.58 0 1.127.107 1.621.334.504.226.934.539 1.298.96.376.398.666.872.87 1.422.214.539.322 1.142.322 1.778a4.86 4.86 0 0 1-.322 1.778 4.467 4.467 0 0 1-.87 1.444c-.364.41-.805.723-1.298.949Z"
          clipRule="evenodd"
        />
        <path d="M124.975 43.326v12.48h2.007l-.033-9.322 6.665 9.322h1.9v-12.48h-2.007l.032 9.075-6.482-9.075h-2.082Zm16.012 0v12.48h2.007l-.032-9.322 6.664 9.322h1.9v-12.48h-2.007l.032 9.075-6.482-9.075h-2.082Zm16.012 0v12.49h8.972v-1.82h-6.836v-3.59h4.722v-1.778h-4.722v-3.48h6.761v-1.822h-8.897Zm16.517 12.189a6.53 6.53 0 0 0 2.522.485 6.19 6.19 0 0 0 1.845-.27 6.068 6.068 0 0 0 1.653-.764 5.551 5.551 0 0 0 1.352-1.197l-1.416-1.39c-.44.539-.955.96-1.546 1.26a4.173 4.173 0 0 1-1.878.432 4.202 4.202 0 0 1-1.685-.334 4.11 4.11 0 0 1-1.352-.949 4.538 4.538 0 0 1-.891-1.444 4.818 4.818 0 0 1-.322-1.778c0-.636.108-1.229.322-1.778a4.635 4.635 0 0 1 .891-1.423 4.38 4.38 0 0 1 1.352-.96 4.166 4.166 0 0 1 1.685-.333 4.3 4.3 0 0 1 3.424 1.692l1.416-1.39a5.318 5.318 0 0 0-1.352-1.197 6.581 6.581 0 0 0-1.653-.765 6.168 6.168 0 0 0-1.845-.27c-.902 0-1.739.162-2.522.496a6.052 6.052 0 0 0-2.061 1.37 6.381 6.381 0 0 0-1.363 2.047 6.422 6.422 0 0 0-.483 2.511c0 .905.161 1.746.483 2.533a6.557 6.557 0 0 0 1.363 2.047 6.56 6.56 0 0 0 2.061 1.37Zm15.293-10.335v10.626h2.135V45.18h4.154v-1.853h-10.443v1.853h4.154Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h205v56H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
