import React from 'react';
import {Link, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {Accordion, BlockContainer} from './shared';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    '&.MuiTypography-root': {
      marginBottom: theme.spacing(3),
    },
  },
}));

const FAQ_WORDINGS = [
  {
    id: 0,
    title: (
      <Typography component="h5" variant="h6">
        How do I transfer records to the requesting patient?
      </Typography>
    ),
    body: (
      <>
        <Typography component="p" variant="body4" paragraph>
          The AllClear Health Network makes it easy to send medical records to
          your patient. Our convenient portal allows you to upload patient
          records, eliminating faxing or burning CDs.
        </Typography>
        <Typography component="p" variant="body4" paragraph>
          After entering your reference code and patient information, simply
          drag and drop records and images. We support these file types: .сcda,
          .cda, .ccd, .xml, .dcm, .pdf, .jpeg, .jpg, .png. Last you save the
          records and your patient is securely notified and is able to view
          their records in their Health ID mobile app.
        </Typography>
        <Typography variant="body4" component="p">
          It gets even easier when you join the network. Records are always
          instantly available for both patients and providers. No more faxing
          requests for records or spending time fulfilling requests.
        </Typography>
      </>
    ),
  },
  {
    id: 1,
    title: (
      <Typography component="h5" variant="h6">
        Who is AllClear ID Health?
      </Typography>
    ),
    body: (
      <>
        <Typography component="p" variant="body4" paragraph>
          AllClear ID® brings nearly two decades of experience building digital
          identity products to healthcare with the AllClear Health Network.
        </Typography>
        <Typography component="p" variant="body4" paragraph>
          The Health Network is a revolutionary new system that connects
          physicians and patients like never before. It does all the work to
          consolidate a patient’s medical records from every provider and puts
          them in one place, so that doctors can finally access complete records
          at any time.
        </Typography>
        <Typography component="p" variant="body4" paragraph>
          During check-in, patients no longer need to fill out forms describing
          their medical history, and they will never be mismatched or duplicated
          in the provider’s EHR system. This reduces back-end revenue cycle
          costs and increases reimbursements.
        </Typography>
        <Typography component="p" variant="body4" paragraph>
          Providers may sign-up to join the AllClear ID Health Network. Once
          onboarded as a healthcare provider, they can invite their patients to
          join the AllClear Health Network. Once your patient enrolls into the
          network, providers can easily transfer patient records directly to
          them as well as receive records directly from the patient. No more
          faxing or entering data manually. Medical records are always instantly
          available for both patients and providers.
        </Typography>
        <Typography variant="body4" component="p">
          Interested in joining the AllClear Health Network?&nbsp;
          <Link target="_blank" href="https://www.allclearid.com/contact-us/">
            Contact us
          </Link>
          &nbsp;for more information.
        </Typography>
      </>
    ),
  },
  {
    id: 2,
    title: (
      <Typography component="h5" variant="h6">
        What is the Cures Act?
      </Typography>
    ),
    body: (
      <>
        <Typography component="p" variant="body4" paragraph>
          The 21st Century Cures Act was signed into law on Dec. 13, 2016. The
          legislation promotes patient access to their electronic health
          information (EIH), supports provider needs, advances innovation, and
          addresses industry-wide information blocking practices.<sup>1</sup>
        </Typography>
        <Typography component="p" variant="body4" paragraph>
          The Cures Act also has provisions that advance interoperability and
          requires healthcare actors not engage in information blocking — or
          preventing or interfering with the access, exchange, or use of
          electronic health information (EHI).<sup>2</sup>
        </Typography>
        <Typography component="p" variant="body4" paragraph>
          Simply put, patients have a right to their healthcare records in
          electronic format.
        </Typography>
        <Typography component="p" paragraph>
          1 The cures act final rule: Interoperability-focused policies that
          empower patients and support providers. (2020, March 09). Retrieved
          January 5, 2023, from&nbsp;
          <Link
            target="_blank"
            href="https://www.healthit.gov/buzz-blog/21st-century-cures-act/the-cures-final-rule"
          >
            https://www.healthit.gov/buzz-blog/21st-century-cures-act/the-cures-final-rule
          </Link>
        </Typography>
        <Typography component="p" paragraph>
          2 Information blocking. (2022, October 31). Retrieved January 5, 2023,
          from&nbsp;
          <Link
            target="_blank"
            href="https://www.healthit.gov/topic/information-blocking"
          >
            https://www.healthit.gov/topic/information-blocking
          </Link>
        </Typography>
      </>
    ),
  },
  {
    id: 3,
    title: (
      <Typography component="h5" variant="h6">
        What is CDA or DICOM format?
      </Typography>
    ),
    body: (
      <>
        <Typography component="p" variant="body4" paragraph>
          The Cures Act requires healthcare providers to give patients their
          medical records in electronic format. Unlike faxes and PDFs, the
          Clinical Document Architecture (CDA) format is compatible with EHRs
          which saves everyone time.
        </Typography>
        <Typography component="p" variant="body4" paragraph>
          Digital Imaging and Communications in Medicine (DICOM) is the standard
          format for medical images and allows doctors to receive image files in
          a readable format to make accurate diagnoses.
        </Typography>
        <Typography variant="body4" component="p">
          Your records systems support the export of both data formats. You can
          find out how to do this by searching for CDA and DICOM format in your
          EHR. You can learn more about CDA format&nbsp;
          <Link
            target="_blank"
            href="https://www.healthit.gov/topic/standards-technology/consolidated-cda-overview"
          >
            here
          </Link>
          &nbsp;and more about DICOM format&nbsp;
          <Link target="_blank" href="https://www.dicomstandard.org/about-home">
            here
          </Link>
          .
        </Typography>
      </>
    ),
  },
  {
    id: 4,
    title: (
      <Typography component="h5" variant="h6">
        Is this secure?
      </Typography>
    ),
    body: (
      <>
        <Typography variant="body4" component="p" paragraph>
          AllClear ID Health, Inc. is committed to the security of our customers
          and their data. We are focused on keeping them safe through strong,
          banking grade authentication and identity technology.
        </Typography>
        <Typography variant="body4" component="p">
          <Link
            target="_blank"
            href="https://www.allclearid.com/security-compliance/"
          >
            Learn more
          </Link>
          &nbsp;about AllClear ID Health Security and Compliance practices.
        </Typography>
      </>
    ),
  },
  {
    id: 5,
    title: (
      <Typography component="h5" variant="h6">
        How do I join the AllClear Health Network?
      </Typography>
    ),
    body: (
      <Typography variant="body4" component="p">
        We’d love for you join us.&nbsp;
        <Link target="_blank" href="https://www.allclearid.com/contact-us/">
          Contact us
        </Link>
        &nbsp;for more information and someone on our team will follow-up.
      </Typography>
    ),
  },
];

function FaqBlock(): JSX.Element {
  const classes = useStyles();

  return (
    <BlockContainer withBackground grow={1}>
      <>
        <Typography
          component="h3"
          variant="h4"
          className={classes.wrapper}
          paragraph
        >
          FAQ
        </Typography>
        <Accordion content={FAQ_WORDINGS} />
      </>
    </BlockContainer>
  );
}

export default FaqBlock;
