import React from 'react';
import {Box, Link, Paper, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

import {BlockHeader} from './shared';

const useStyles = makeStyles((theme: Theme) => ({
  paperRoot: {
    backgroundColor: `${theme.palette.grey[100]}`,
    padding: theme.spacing(2, 3),
    '&.MuiPaper-root': {
      borderRadius: theme.spacing(1),
    },
  },
}));

function InfoBlock(): JSX.Element {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.paperRoot} data-testid="info-block">
      <BlockHeader title="Need Help?" />
      <Box marginTop={2}>
        <Typography>
          If you have questions or need help please&nbsp;
          <Link
            target="_blank"
            href="https://allclearid.atlassian.net/servicedesk/customer/portals"
          >
            contact support
          </Link>
        </Typography>
      </Box>
    </Paper>
  );
}

export default InfoBlock;
